import { IGalleryThumb } from '@models/interfaces/gallery-pic';
import { IGallery, GalleryItemType, TLightboxItem } from '@interfaces';
import { environment as env } from '../../../../environments/environment';
import { TokenPipe } from '@core/pipes/token.pipe';

type TGalleryThumbArgs = Partial<IGalleryThumb> & { pictureFormats: IGallery['pictureFormats']; galleryUuid: string };

export class GalleryThumb implements IGalleryThumb {
  public uuid: string = '';
  public caption: string = '';
  public itemType: GalleryItemType = 'IMAGE';
  public picUrl: string = '';
  public thumbUrl: string = '';
  public downloadUrl: string = '';

  private pictureFormats: IGallery['pictureFormats'] = null;

  constructor(args: TGalleryThumbArgs) {
    this.setProps(args);
  }

  public asLightboxItem(): TLightboxItem {
    if (this.itemType !== 'VIDEO') {
      return { src: this.picUrl, thumb: this.thumbUrl, downloadUrl: this.downloadUrl };
    }

    return {
      src: null,
      video: {
        source: [
          {
            src: TokenPipe.transform(`${env.apiUrl}/items/vids/${this.uuid}`),
            type: 'video/mp4',
          },
        ],
        tracks: [],
        attributes: { autoplay: false, controls: true } as unknown as HTMLVideoElement,
      },
      thumb: this.thumbUrl,
      downloadUrl: this.downloadUrl,
    };
  }

  private setProps(args: TGalleryThumbArgs) {
    Object.entries(args).forEach(entry => {
      const key = entry[0] as keyof IGalleryThumb;
      const value = entry[1] as IGalleryThumb[typeof key];

      if (value && this.hasOwnProperty(key)) {
        Reflect.set(this, key, value);
      }
    });

    this.thumbUrl = `${env.apiUrl}/items/thumbs/${this.uuid}.${this.pictureFormats.thumbs}`;
    this.picUrl = this.itemType === 'IMAGE' ? `${env.apiUrl}/items/pics/${this.uuid}.${this.pictureFormats.thumbs}` : null;
    this.downloadUrl = TokenPipe.transform(`${env.apiUrl}/downloads/items/${this.uuid}`);
  }
}
