import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Gallery } from '@core/models/classes/gallery.class';
import { routerSelectors } from '@store/router/router.selectors';

import { IGalleryState } from '@store/gallery/galleries.reducer';

const selectState = createFeatureSelector<IGalleryState>('galleries');
const selectEntities = createSelector(selectState, state => (state.ids || []).map(id => state.entities[id]));
const selectGalleries = createSelector(selectEntities, entities => structuredClone(entities).map(entity => new Gallery(entity)));

const selectCurrentGalleries = createSelector(routerSelectors.selectParams, selectEntities, (params, entities) => {
  if (!params?.year) {
    return [];
  }

  const parts = params.year.split('-');
  const startYear = parseInt(parts[0], 10);
  const endYear = parseInt(parts[1] || parts[0], 10);
  const filtered = entities.filter(e => e.year >= startYear && e.year <= endYear);

  return structuredClone(filtered).map(entity => new Gallery(entity));
});

const selectById = (galleryId: number) =>
  createSelector(selectState, state => {
    const entity = state.entities[galleryId];
    return entity ? new Gallery(structuredClone(entity)) : null;
  });

const selectByUuid = (galleryUuid: string) =>
  createSelector(selectState, state => {
    const entity = Object.values(state.entities).find(g => g.uuid === galleryUuid);
    return entity ? new Gallery(structuredClone(entity)) : null;
  });

const selectTreeData = createSelector(selectState, state => state.treeData ?? []);

const selectYears = createSelector(selectTreeData, treeData => {
  return Array.from(new Set(treeData.reduce((p, c) => [...p, ...c.children.map(node => parseInt(node.label, 10))], [])))
    .toSorted()
    .toReversed();
});

const selectDecades = createSelector(selectTreeData, treeData => {
  return treeData.map(({ startYear, endYear }) => ({ startYear, endYear }));
});

const selectSize = createSelector(selectState, state => state.total ?? 0);

export const gallerySelectors = {
  selectState,
  selectEntities,
  selectGalleries,
  selectCurrentGalleries,
  selectTreeData,
  selectDecades,
  selectById,
  selectByUuid,
  selectYears,
  selectSize,
};
