import { environment, environment as env } from '../../../../environments/environment';
import { GalleryItemCollection, IApiGallery, IGallery, TLightboxItem } from '@core/models/interfaces';
import { GalleryThumb } from '@models/classes/gallery-thumb.class';

export class Gallery implements IGallery {
  public id: number = null;
  public uuid: string = null;
  public createdAt: Date = new Date();
  public updatedAt: Date = null;
  public publishedAt: Date = null;
  public year = new Date().getFullYear();
  public month = new Date().getMonth() + 1;
  public label = 'New Gallery';
  public slug: string = null;
  public dateString: string = '';
  public pictureFormats: IGallery['pictureFormats'] = {
    pics: 'webp',
    thumbs: 'webp',
  };
  public thumbs: GalleryThumb[] = [];
  public galleryItems: GalleryItemCollection = { files: 0, images: 0, thumbs: 0, pics: 0, videos: 0, items: [] };
  public lightboxItems: TLightboxItem[] = [];
  public poster: string = null;
  public zip: number = 0;
  public accessLevel = 1;
  public path: string = null;

  constructor(args: Partial<IApiGallery> = {}) {
    this.setProps(args);
  }

  get persisted(): boolean {
    return !!this.uuid;
  }

  get cacheKey(): string {
    return [this.uuid, this.updatedAt.getTime()].join('-');
  }

  get published(): boolean {
    return this.publishedAt && this.publishedAt < new Date();
  }

  get ok(): boolean {
    const { images, pics, thumbs, videos } = this.galleryItems;

    if (images !== pics) {
      return false;
    }

    return images + videos === thumbs;
  }

  get downloadUrl(): string {
    return [env.apiUrl, 'downloads', 'gallery', this.uuid].join('/');
  }

  public serialized(): Omit<IApiGallery, 'galleryItems' | 'poster'> {
    return {
      id: this.id,
      uuid: this.uuid,
      createdAt: this.createdAt?.toISOString(),
      publishedAt: this.publishedAt?.toISOString(),
      updatedAt: this.updatedAt?.toISOString(),
      year: this.year,
      month: this.month,
      label: this.label,
      slug: this.slug,
      dateString: this.dateString,
      pictureFormats: this.pictureFormats,
      accessLevel: this.accessLevel,
      path: this.path,
    };
  }

  public buildLightboxItems(): void {
    this.lightboxItems = this.thumbs.map(thumb => {
      const subHtml = thumb.caption || `<span class='label'>${this.label}</span><time>${this.dateString}</time>`;

      return { ...thumb.asLightboxItem(), subHtml };
    });
  }

  public buildThumbs(): void {
    this.thumbs = (this.galleryItems.items || [])
      .filter(item => item.thumb || item.pic)
      .map(
        ({ uuid, itemType, caption }) =>
          new GalleryThumb({
            uuid,
            itemType,
            caption,
            galleryUuid: this.uuid,
            pictureFormats: this.pictureFormats,
          })
      );
  }

  private setProps(args: Partial<IApiGallery>): void {
    Object.entries(args).forEach(entry => {
      const key = entry[0] as keyof IApiGallery;
      const value = entry[1] as IApiGallery[typeof key];

      if (!this.hasOwnProperty(key)) {
        return;
      }

      if (['publishedAt', 'updatedAt', 'createdAt'].includes(key) && value) {
        Reflect.set(this, key, new Date(value as string));
        return;
      }

      if (key === 'poster') {
        Reflect.set(this, key, value ? `${environment.apiUrl}/${value}` : null);
        return;
      }

      Reflect.set(this, key, value);
    });

    this.buildThumbs();
    this.buildLightboxItems();
  }
}
