import { createSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

const {
  selectFragment,
  selectQueryParams, // select the current route query params
  selectRouteParams, // select the current route params
} = fromRouter.getRouterSelectors();

export const routerSelectors = {
  selectQueryParams,
  selectParams: selectRouteParams,
  selectFragment,
  selectAllParams: createSelector(selectQueryParams, selectRouteParams, (queryParams, params) => ({
    queryParams,
    params,
  })),
  selectParam: (needle: string) =>
    createSelector(routerSelectors.selectAllParams, ({ queryParams, params }) => {
      const value = params?.[needle] || queryParams?.[needle];
      return value ? (value as string) : null;
    }),
};
